<template>
  <div class="datum">
    <div class="template-page-top">
      <div class="title">个人资料</div>
    </div>
    <el-form
      class="animated fadeInUp"
      ref="form"
      :model="form"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入姓名"
          maxlength="20"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="手机号"
        v-model="form.phone"
        :label-width="formLabelWidth"
        prop="phone"
      >
        <!-- @blur="getOccupyPhoneApi" -->
        <el-input
          type="number"
          v-model="form.phone"
          disabled
          autocomplete="off"
          placeholder="请输入手机号"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="uid"
        v-model="form.uid"
        :label-width="formLabelWidth"
        prop="uid"
      >
        <el-input
          v-model.trim="form.uid"
          autocomplete="off"
          placeholder="请输入uid"
          maxlength="20"
          @blur="getOccupyUidApi"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button
        class="ok-button animated fadeInUp"
        type="primary"
        @click="okAdd('form')"
      >
        确 定
      </el-button>
    </div>
  </div>
</template>
<script>
import { postEditUserInfoApi, getOccupyUidApi } from "@/request/api";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      msgUid: "",
      msgPhone: "",
      form: {
        name: "",
        uid: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        uid: [{ required: true, message: "请输入uid", trigger: "blur" }],
        phone: [
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机格式错误",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "150px",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.userInfo));
  },
  methods: {
    ...mapMutations("user", ["SET_USERINFO"]),

    async getOccupyUidApi() {
      const { code, results } = await getOccupyUidApi({
        uid: this.form.uid,
      });
      if (code !== 0) return;
      this.msgUid = results.exists;
      if (results.exists) {
        this.$notify.error({
          title: "错误",
          message: "该Uid已被占用",
        });
      }
    },
    async postEditUserInfoApi() {
      const { code, msg } = await postEditUserInfoApi({
        name: this.form.name,
        photo: this.form.photo,
        uid: this.form.uid,
      });
      if (code === 0) {
        this.$notify({
          title: "提示",
          message: "修改成功！",
          type: "success",
        });
        let infos = this.userInfo;
        infos.name = this.form.name;
        infos.photo = this.form.photo;
        infos.uid = this.form.uid;
        localStorage.setItem("userInfoObj", JSON.stringify(infos));
        this.SET_USERINFO(infos);
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    okAdd(formName) {
      if (this.msgUid) {
        this.$notify.error({
          title: "错误",
          message: "该Uid已被占用",
        });
      } else if (this.msgPhone) {
        this.$notify.error({
          title: "错误",
          message: "该手机号已被占用",
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.postEditUserInfoApi();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.datum {
  .template-page-top {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    margin-bottom: 22px;
    .title {
      width: 50%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
      color: #fff;
    }
  }
  /deep/.el-input__inner {
    background-color: #081c30;
    border: 1px solid #385576;
    color: #57708d;
    width: 282px;
  }

  /deep/.el-form-item__label {
    font-size: 14px;
    color: #fff;
    padding: 0 24px 0 0;
  }

  /deep/.dialog-footer {
    padding: 0 0 0 150px;

    .el-form-item__content {
      width: 353px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
